var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex-column cate" }, [
    _c("div", { staticClass: "ywml" }, [
      _c(
        "div",
        {
          staticClass: "tree-container1",
          staticStyle: {
            height: "700px",
            "overflow-y": "auto",
            width: "300px",
          },
        },
        [
          _c("div", { staticClass: "tree-node" }, [
            _c(
              "div",
              {
                staticClass: "node-header",
                on: {
                  click: function ($event) {
                    return _vm.toggleCollapse(1)
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "el-icon-folder aac",
                  style: { color: _vm.getRandomColor() },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "node-name" }, [_vm._v("行业")]),
                _c("i", {
                  class: [
                    "el-icon-arrow-right",
                    _vm.isCollapsed1 ? "collapsed" : "",
                  ],
                }),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isCollapsed1,
                    expression: "!isCollapsed1",
                  },
                ],
                staticClass: "node-children",
              },
              _vm._l(_vm.list, function (parent, parentIndex) {
                return _c(
                  "div",
                  {
                    key: parentIndex,
                    staticClass: "child-node",
                    style: _vm.parentStyle(parent, parentIndex),
                    attrs: {
                      "header-cell-style": {
                        background: "#eef1f6",
                        color: "#606266",
                      },
                      "row-class-name": _vm.setTableRowClassName,
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-folder aac",
                      style: { color: _vm.getRandomColor() },
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "node-name",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.loadChildren(parent, parentIndex)
                          },
                        },
                      },
                      [_vm._v(_vm._s(parent.system_category_name))]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: parent.isCollapsed,
                            expression: "parent.isCollapsed",
                          },
                        ],
                        staticClass: "node-children",
                      },
                      _vm._l(parent.children, function (child, childIndex) {
                        return _c(
                          "div",
                          {
                            key: childIndex,
                            staticClass: "child-node axx",
                            style: {
                              height: "20px",
                              marginBottom: "10px !important",
                              fontWeight: _vm.rowFontWeight2(childIndex),
                            },
                            attrs: {
                              "header-cell-style": {
                                background: "#eef1f6",
                                color: "#606266",
                              },
                              "row-class-name": _vm.setTableRowClassName2,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.rowClickFirstTable4(
                                  child,
                                  childIndex
                                )
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-folder aac",
                              style: { color: _vm.getRandomColor() },
                            }),
                            _c("span", { staticClass: "node-name" }, [
                              _vm._v(_vm._s(child.system_category_name)),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "tree-container2",
          staticStyle: {
            height: "700px",
            "overflow-y": "auto",
            width: "300px",
          },
        },
        [
          _c("div", { staticClass: "tree-node" }, [
            _c(
              "div",
              {
                staticClass: "node-header",
                on: {
                  click: function ($event) {
                    return _vm.toggleCollapse(2)
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "el-icon-folder aac",
                  style: { color: _vm.getRandomColor() },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "node-name" }, [_vm._v("管理体系")]),
                _c("i", {
                  class: [
                    "el-icon-arrow-right",
                    _vm.isCollapsed2 ? "collapsed" : "",
                  ],
                }),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isCollapsed2 && _vm.showList4Content,
                    expression: "!isCollapsed2 && showList4Content",
                  },
                ],
                staticClass: "node-children",
              },
              _vm._l(_vm.list4, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "child-node",
                    style: {
                      height: "20px",
                      marginBottom: "10px !important",
                      fontWeight: _vm.rowFontWeight3(index),
                    },
                    attrs: {
                      "header-cell-style": {
                        background: "#eef1f6",
                        color: "#606266",
                      },
                      "row-class-name": _vm.setTableRowClassName2,
                    },
                    on: {
                      click: function ($event) {
                        _vm.showFile = true
                        _vm.rowClickFirstTable5(item, index)
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-folder aac",
                      style: { color: _vm.getRandomColor() },
                    }),
                    _c("span", { staticClass: "node-name" }, [
                      _vm._v(_vm._s(item.system_category_name)),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
        ]
      ),
    ]),
    _vm.showFile
      ? _c("div", { staticClass: "folder-container" }, [
          _c(
            "div",
            {
              staticClass: "folder-icon",
              on: {
                click: function ($event) {
                  return _vm.navigateToTemplate("管理手册")
                },
              },
            },
            [
              _c("img", {
                staticClass: "folder-image bbc",
                staticStyle: { width: "85px", height: "85px" },
                attrs: { src: require("../../../public/wenjian.png") },
              }),
              _c("div", { staticClass: "folder-label" }, [_vm._v("管理手册")]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "folder-icon",
              on: {
                click: function ($event) {
                  return _vm.navigateToTemplate("程序文件")
                },
              },
            },
            [
              _c("img", {
                staticClass: "folder-image bbc",
                staticStyle: { width: "85px", height: "85px" },
                attrs: { src: require("../../../public/wenjian.png") },
              }),
              _c("div", { staticClass: "folder-label" }, [_vm._v("程序文件")]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "folder-icon",
              on: {
                click: function ($event) {
                  return _vm.navigateToTemplate("作业文件")
                },
              },
            },
            [
              _c("img", {
                staticClass: "folder-image bbc",
                staticStyle: { width: "85px", height: "85px" },
                attrs: { src: require("../../../public/wenjian.png") },
              }),
              _c("div", { staticClass: "folder-label" }, [_vm._v("作业文件")]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "folder-icon",
              on: {
                click: function ($event) {
                  return _vm.navigateToTemplate("运行记录")
                },
              },
            },
            [
              _c("img", {
                staticClass: "folder-image bbc",
                staticStyle: { width: "85px", height: "85px" },
                attrs: { src: require("../../../public/wenjian.png") },
              }),
              _c("div", { staticClass: "folder-label" }, [_vm._v("运行记录")]),
            ]
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "vertical-line2" }),
    _c(
      "div",
      { staticClass: "cccc" },
      [
        _vm.showBackButton
          ? _c("div", { staticClass: "fname" }, [
              _c("h3", [_vm._v(_vm._s(_vm.folderName))]),
            ])
          : _vm._e(),
        _vm.showBackButton
          ? _c(
              "div",
              { staticClass: "vuetable" },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.filteredDatainfo },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "template_level", label: "序号" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "template_description",
                        label: "所属目录",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "template_name2", label: "行业" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "parent_id", label: "管理体系" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "template_name", label: "文件名称" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "add_user", label: "创建人" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "add_time", label: "创建时间" },
                    }),
                    _c("el-table-column", { attrs: { label: "修改人" } }),
                    _c("el-table-column", { attrs: { label: "修改时间" } }),
                    _c("el-table-column", {
                      attrs: { label: "操作" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "danger", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteRow(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1749346704
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "解析" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "success", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openDialog(
                                          scope.row.template_name
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("解析")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4284821750
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.showBackButton
          ? _c("div", { staticClass: "document" }, [
              _c("div", { staticClass: "d-header" }, [_vm._v("填写文档资料")]),
              _c(
                "div",
                { staticClass: "btn" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "success" },
                      on: { click: _vm.handleCreate },
                    },
                    [_vm._v("模板上传")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "fanhui",
                      attrs: { type: "success" },
                      on: { click: _vm.navigateToTemplate1 },
                    },
                    [_vm._v("返回页面")]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _c(
          "el-dialog",
          {
            directives: [
              { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
            ],
            staticClass: "dialog-mini",
            attrs: {
              width: "500px",
              title: _vm.textMap[_vm.dialogStatus],
              visible: _vm.dialogFormVisible,
            },
            on: {
              "update:visible": function ($event) {
                _vm.dialogFormVisible = $event
              },
            },
          },
          [
            _c(
              "el-upload",
              {
                attrs: {
                  drag: "",
                  "file-list": _vm.fileList,
                  name: "files",
                  headers: _vm.tokenHeader,
                  action: _vm.baseURL + "/mzysystem_categorys/Upload",
                  data: { category2id: _vm.clickedRowId },
                  multiple: "",
                  "on-success": _vm.handleUploadSuccess,
                },
              },
              [
                _c("i", { staticClass: "el-icon-upload" }),
                _c("div", { staticClass: "el-upload__text" }, [
                  _vm._v("将文件拖到此处，或"),
                  _c("em", [_vm._v("点击上传")]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "el-upload__tip",
                    attrs: { slot: "tip" },
                    slot: "tip",
                  },
                  [_vm._v("上传docx,exls文档")]
                ),
              ]
            ),
            _c(
              "div",
              { attrs: { slot: "footer" }, slot: "footer" },
              [
                _c(
                  "el-button",
                  { attrs: { size: "mini" }, on: { click: _vm.closeUpload } },
                  [_vm._v("关闭")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            directives: [
              { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
            ],
            staticClass: "dialog-mini",
            attrs: {
              width: "90%",
              title: "模板预览",
              visible: _vm.dialogVisible,
            },
            on: {
              "update:visible": function ($event) {
                _vm.dialogVisible = $event
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "form",
                staticStyle: { height: "auto", overflow: "auto" },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.formData,
                      rules: _vm.formRules,
                      "label-width": "450px",
                    },
                  },
                  _vm._l(_vm.datalist, function (item, index) {
                    return _c(
                      "el-form-item",
                      {
                        key: index,
                        attrs: { label: item.name, prop: item.name },
                      },
                      [
                        _c("span", { staticClass: "item-value" }, [
                          _vm._v(_vm._s(item.value)),
                        ]),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: {
                      "background-color": "green",
                      color: "white",
                      "font-weight": "bold",
                    },
                    attrs: { type: "default" },
                    on: {
                      click: function ($event) {
                        _vm.dialogVisible = false
                      },
                    },
                  },
                  [_vm._v("关闭")]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }