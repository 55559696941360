<!--
 * @description: 代码生成器自动生成
 * @author: liyubao | xufuxing
 * @version: 1.0
-->
<template>
  <div class="flex-column cate">
    <!-- <h3 style="color: #8c8989">业务目录管理</h3> -->
    <div class="ywml">
      <div class="tree-container1" style="height: 700px; overflow-y: auto; width: 300px;">
        <div class="tree-node">
          <div class="node-header" @click="toggleCollapse(1)">
            <i class="el-icon-folder aac" :style="{ color: getRandomColor() }"></i> <!-- 文件夹图标 -->
            <span class="node-name">行业</span>
            <i :class="['el-icon-arrow-right', isCollapsed1 ? 'collapsed' : '']"></i>
          </div>
          <div v-show="!isCollapsed1" class="node-children">
            <div v-for="(parent, parentIndex) in list" 
              :key="parentIndex" 
              :style="parentStyle(parent, parentIndex)"
              :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
              :row-class-name="setTableRowClassName"
              class="child-node">
              
              <i class="el-icon-folder aac" :style="{ color: getRandomColor() }"></i> 
              <span class="node-name" @click.stop="loadChildren(parent, parentIndex)">{{ parent.system_category_name }}</span>
              <!-- <i class="el-icon-close" @click.stop="handleDelete(parent)" style="cursor: pointer; margin-left: 10px;"></i> -->
              
              <div v-show="parent.isCollapsed" class="node-children">
                  <div v-for="(child, childIndex) in parent.children" 
                      :key="childIndex" 
                      :style="{ height: '20px', marginBottom: '10px !important', fontWeight: rowFontWeight2(childIndex) }"
                      :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                      :row-class-name="setTableRowClassName2"
                      class="child-node axx"
                      @click="rowClickFirstTable4(child, childIndex)">
                      
                      <i class="el-icon-folder aac" :style="{ color: getRandomColor() }"></i> 
                      <span class="node-name">{{ child.system_category_name }}</span>
                      <!-- <i class="el-icon-close" @click.stop="handleDelete(child)" style="cursor: pointer; margin-left: 10px;"></i> -->
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tree-container2"  style="height: 700px; overflow-y: auto; width: 300px;">
        <div class="tree-node">
          <div class="node-header" @click="toggleCollapse(2)">
            <i class="el-icon-folder aac" :style="{ color: getRandomColor() }"></i> <!-- 文件夹图标 -->
            <span class="node-name">管理体系</span>
            <i :class="['el-icon-arrow-right', isCollapsed2 ? 'collapsed' : '']"></i>
          </div>
          <div v-show="!isCollapsed2 && showList4Content" class="node-children">
            <div v-for="(item, index) in list4" 
                :key="index" 
                :style="{ height: '20px', marginBottom: '10px !important', fontWeight: rowFontWeight3(index) }"
                :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                :row-class-name="setTableRowClassName2"
                class="child-node"
                @click="showFile = true; rowClickFirstTable5(item, index)">
                <i class="el-icon-folder aac" :style="{ color: getRandomColor() }"></i> 
                <span class="node-name">{{ item.system_category_name }}</span>
                <!-- <i class="el-icon-close" @click.stop="handleDelete(item)" style="cursor: pointer; margin-left: 10px;"></i> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="folder-container" v-if="showFile">
      <div class="folder-icon" @click="navigateToTemplate('管理手册')">
        <img src="../../../public/wenjian.png" class="folder-image bbc" style="width: 85px; height: 85px;"> 
        <div class="folder-label">管理手册</div>
      </div>
      

      <div class="folder-icon" @click="navigateToTemplate('程序文件')">
        <img src="../../../public/wenjian.png" class="folder-image bbc" style="width: 85px; height: 85px;"> 
        <div class="folder-label">程序文件</div>
      </div>
      

      <div class="folder-icon" @click="navigateToTemplate('作业文件')">
        <img src="../../../public/wenjian.png" class="folder-image bbc" style="width: 85px; height: 85px;"> 
        <div class="folder-label">作业文件</div>
      </div>
      

      <div class="folder-icon" @click="navigateToTemplate('运行记录')">
        <img src="../../../public/wenjian.png" class="folder-image bbc" style="width: 85px; height: 85px;"> 
        <div class="folder-label">运行记录</div>
      </div>
      
    </div>

    <div class="vertical-line2"></div>
    <!-- <div class="vertical-line3"></div> -->

  <div class="cccc"> 
    <div class = "fname" v-if="showBackButton">
      <h3>{{ folderName }}</h3> <!-- 展示文件夹名称 -->
    </div>

    <div class="vuetable" v-if="showBackButton">
      <el-table :data="filteredDatainfo" style="width: 100%">
        <el-table-column prop="template_level" label="序号"></el-table-column>
        <el-table-column prop="template_description" label="所属目录"></el-table-column>
        <el-table-column prop="template_name2" label="行业"></el-table-column>
        <el-table-column prop="parent_id" label="管理体系"></el-table-column>
        <el-table-column prop="template_name" label="文件名称"></el-table-column>
        <el-table-column prop="add_user" label="创建人"></el-table-column>
        <el-table-column prop="add_time" label="创建时间"></el-table-column>
        <el-table-column label="修改人"></el-table-column>
        <el-table-column label="修改时间"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="danger" size="mini" @click="deleteRow(scope.row)">删除</el-button>
          </template>
        </el-table-column>
        <el-table-column label="解析">
          <template slot-scope="scope">
            <el-button
              type="success"
              size="mini"
              @click="openDialog(scope.row.template_name)"
              >解析</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="document" v-if="showBackButton">
      <div class="d-header">填写文档资料</div>
      <div class="btn">
        <!-- <el-button v-if="selectedRow3.template_description" type="warning"
          >预览</el-button
        > -->

        <el-button
          
          type="success"
          @click="handleCreate"
          >模板上传</el-button
        >

        <!-- <el-button
          v-if="selectedRow3.template_description"
          type="danger"
          @click="handleCreate"
          >重新上传</el-button
        >

        <el-button
          v-if="selectedRow3.template_description"
          type="success"
          @click="Parsingform"
          >模版解析表单</el-button
        > -->
        
        <el-button class="fanhui"
          
          type="success"
          @click="navigateToTemplate1"
          >返回页面</el-button
        >
      </div>
    </div>
    <el-dialog
      v-el-drag-dialog
      class="dialog-mini"
      width="500px"
      :title="textMap[dialogStatus]"
      :visible.sync="dialogFormVisible"
    >
      <el-upload
        drag
        :file-list="fileList"
        name="files"
        :headers="tokenHeader"
        :action="baseURL + '/mzysystem_categorys/Upload'"
        :data="{ category2id: clickedRowId }"
        multiple=""
        :on-success="handleUploadSuccess"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">上传docx,exls文档</div>
      </el-upload>

      <div slot="footer">
        <el-button size="mini" @click="closeUpload">关闭</el-button>
      </div>
    </el-dialog>

    <el-dialog
      v-el-drag-dialog
      class="dialog-mini"
      width="90%"
      title="模板预览"
      :visible.sync="dialogVisible"
    >
      <div
        class="form"
        
        style="height: auto; overflow: auto"
      >
        <el-form
          :model="formData"
          :rules="formRules"
          ref="form"
          label-width="450px"
        >
          <el-form-item
            v-for="(item, index) in datalist"
            :label="item.name"
            :prop="item.name"
            :key="index"
          >
           
            <!-- <el-input v-model="item.value"></el-input> -->
            <!-- 将文本框改为括号形式 -->
            <span class="item-value">{{ item.value }}</span>
          </el-form-item>

          
        </el-form>
      </div>
      <!-- 关闭按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" type="default" style="background-color: green; color: white; font-weight: bold;">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</div>
</template>
<script>
import * as mzysystem_category2s from "@/api/mzysystem_category2s";
import * as mzysystem_categorys from "@/api/mzysystem_categorys";
import waves from "@/directive/waves"; // 水波纹指令
import { getToken } from "@/utils/auth";
import elDragDialog from "@/directive/el-dragDialog";
import extend from "@/extensions/delRows.js";
export default {
  name: "mzysystem_category",
  directives: {
    waves,
    elDragDialog,
  },
  mixins: [extend],
  data() {
    return {
      // 一级的数据
      list: [], // 子目录列表
      ltinfo:[],
      folderName: '',
      isCollapsed1: false, // 是否折叠
      isCollapsed2: false, // 是否折叠
      isCollapsed3: true, // 是否折叠
      isCollapsed4: true, // 是否折叠
      isCollapsed5: true, // 是否折叠
      isCollapsed6: true, // 是否折叠
      selectedRow: {}, //选中行
      inputtext1: "",
      clickedRowText: '', // 用于存储点击行的文本值
      clickedRowText2: '', // 用于存储点击行的文本值
      // 二级的数据
      list3: null, //数据源
      list4: null,
      selectedRow2: {}, //选中行
      inputtext2: "",
      wenben: "",
      wenben2: "",
      wenben3: "",
      textMap: {
        update: "编辑",
        create: "添加",
      },
      dialogVisible: false, // 控制模态框显示与隐藏的变量
      dialogStatus: "",
      dialogFormVisible: false,
      visible:false,
      tokenHeader: { "X-Token": getToken() },
      baseURL: process.env.VUE_APP_BASE_API, // api的base_url
      formData: {
        name: "",
        age: "",
        gender: "",
      },
      fileList:[],
      datalist: [1,2,3,4,5,6],
      uploadedFileName: '', // 用于保存已上传文件的名称
      name1:"",
      clickedRowIndex: -1, // 存储当前点击行的索引，默认为 -1 表示未点击任何行
      clickIndex:-1,
      click3:-1,
      showBackButton: false, // 控制返回按钮的显示与隐藏
      showFile: false,
      clickedRowId: "", // 定义用于存储点击行的 ID 的变量
      // 四级文件
      ServiceDirectoryList1: [],
      ServiceDirectoryList2: [],
      ServiceDirectoryList3: [],
      ServiceDirectoryList4: [],
      inputtext3: "",
      inputtext4: "",
      inputtext5: "",
      inputtext6: "",
      selectedRow3: {}, //选中行
      filteredDatainfo: [], // 新的数组用于存放符合条件的数据
      fff:[],
      datainfo:[],
      listQuery: {
        // 查询条件
        page: 1,
        limit: 20,
        key: undefined,
      },
    };
  },
  created() {
    //开始先拿第一层数据源
    this.getList();
    // this.folderName = this.$route.params.folderName; // 获取传递过来的文件夹名称
    // this.$watch('$route', this.onRouteChange); // 监听路由变化
    
  },
  mounted() {
    //this.folderName = this.$route.params.folderName; // 获取传递过来的文件夹名称
  },
  methods: {
    loadChildrenFromBackend(item) {
      // Example API call simulation
      // This should be replaced with your actual API call logic
      setTimeout(() => {
        // Simulate an API response setting list4
        // this.list4 = [
        //   { parentId: item.id, system_category_name: 'Child 1 of ' + item.system_category_name },
        //   { parentId: item.id, system_category_name: 'Child 2 of ' + item.system_category_name }
        // ];

        // Find the parent in list3 and set its children
        const parentIndex = this.list3.findIndex(parent => parent.id === item.id);
        if (parentIndex !== -1) {
          this.$set(this.list3[parentIndex], 'children', this.list4);
          this.$set(this.list3[parentIndex], 'isCollapsed', !this.list3[parentIndex].isCollapsed);
        }
      }, 500);
    },
    loadChildren(parent, index) {
      this.selectedRow = parent;
      this.clickedRowText = parent.system_category_name;
      console.log("wenben1:",parent.system_category_name);
      this.wenben = parent.system_category_name;
      if (!parent.children || parent.children.length === 0) {
          this.rowClickFirstTable(parent, index);
          this.showList4Content = false; // 隐藏 list4 的内容
      } else {
          this.rowClickFirstTable(parent, index);
          this.showList4Content = true; // 显示 list4 的内容
      }
    },
    parentStyle(parent, index) {
      return {
        marginBottom: '10px',
        fontWeight: this.rowFontWeight(index),
        height: parent.isCollapsed ? 'auto' : '20px',
        overflow: parent.isCollapsed ? 'visible' : 'hidden'
      };
    },
    getRandomColor() {
      // 主要颜色数组
      const colors = ['#ff0000', '#ffff00', '#0000ff', '#00ff00']; // 红黄蓝绿

      // 从主要颜色数组中随机选择一个颜色返回
      return colors[Math.floor(Math.random() * colors.length)];
    },
    toggleCollapse(index) {
      this['isCollapsed' + index] = !this['isCollapsed' + index];
    },
    openDialog(templateName) {
      console.log("iii111");
      this.Parsingform(templateName); // 调用 Parsingform 方法
      this.dialogVisible = true; // 打开模态框
    },
    //解析表单
    Parsingform(templateName) {
      this.datalist = [];
      console.log("iii222");
      console.log("filename", templateName);
      var temp = {
        page: 1,
        limit: 20,
        filename: templateName,
      };
      mzysystem_categorys.GettemplatefieldsByfile(temp).then((data) => {
        this.datalist = data.obj;
        console.log("datalist", this.datalist);
        this.visible = true;
      });
      console.log("iii333");
    },

    // 返回行的字体粗细样式
    rowFontWeight(index) {
      return index === this.clickedRowIndex ? 'bold' : 'normal';
    },
    rowFontWeight2(index) {
      return index === this.clickIndex ? 'bold' : 'normal';
    },
    navigateToTemplate(text) {
      //this.$router.push({ name: '模板管理', params: { folderName } });      
      this.folderName = text;
      console.log("11111111",this.folderName);
      //this.getinfo();
      this.getinfo().then(() => {
        this.updateFilteredDatainfo();
        this.showBackButton = true;
        this.showFile = false;
      });
      // this.updateFilteredDatainfo();
      // this.showBackButton = true;
      // this.showFile = false;
      
    },
    navigateToTemplate1() {
      //this.$router.push({ name: '模板管理' });
      this.showBackButton = false;
      this.showFile = true;
    },
    fetchName() {
      mzysystem_categorys.getNmae().then(response => {
        this.name1 = response.data; // 将返回的用户名存储在组件的数据中
      }).catch(error => {
        console.error('Error fetching name:', error);
      });
    },
    closeUpload() {
      //关闭上传对话框

      this.dialogFormVisible = false;
    },
    handleCreate() {
      // 弹出添加框
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
    },
    handleUploadSuccess(response, file, fileList) {
      // 上传成功后，将文件名保存到 uploadedFileName 字段中
      this.uploadedFileName = file.name;
      console.log("response",response);
      console.log("fileList",fileList);
      console.log("文本2", this.wenben2);
      console.log("文本3",this.wenben3);

      // 获取当前数组的长度，即当前数据的序号
      const index = this.datainfo.length + 1;
      this.fetchName();
      console.log("this.clickedRowText", this.clickedRowText);
      const newData = {
        id: this.generateUniqueId(), // 生成唯一 ID
        template_level: index,
        //add_time: formattedDate, // 使用 ISO 格式的日期字符串
        template_name: this.uploadedFileName,
        template_description: this.folderName,
        Template_name2: this.wenben2,
        parent_id: this.wenben3
      };

      // 向后端发送请求，将数据保存到数据库中
      // 发送网络请求
      mzysystem_category2s.add(newData).then((response) => {  
        // 请求成功后的处理逻辑        
        console.log('Data saved successfully:', response.message);
        // 在前端更新数据
        //this.datainfo.push(newData);
        
        //this.folderName = newData.template_description;
        // this.datainfo.push({
        //   序号: index,
        //   名称: 'System', // 如果名称是固定的，直接填入固定值即可
        //   日期: response.data.formattedDate,
        //   文件名称: this.uploadedFileName,
        //   所属目录: this.folderName,
        //   行业: this.clickedRowText,
        //   管理体系: this.clickedRowText2
        // });
        this.getinfo().then(() => {
          this.updateFilteredDatainfo();
        });
        
        // 可以添加其他提示用户操作成功的逻辑
      }).catch(error => {
        // 请求失败后的处理逻辑
        console.error('Failed to save data:', error);
        // 可以添加提示用户操作失败的逻辑
      });
    },
    generateUniqueId() {
      return Math.random().toString(36).substr(2, 9); // 使用随机数和36进制字符串生成ID
    },
    rowClickFirstTable5(row, index) {
      // 行点击
      this.showBackButton = false;
      this.showFile = true;
      if(this.wenben3 != ""){
        // 弹出确认框
        this.$confirm('是否切换到另一个目录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // 用户点击“确定”后的处理逻辑
          this.click3 = index; // 更新当前点击行的索引
          console.log("hang_id3", row.id);
          console.log("wenben3:", row.system_category_name);
          this.wenben3 = row.system_category_name;
          this.selectedRow3 = row;
          this.showBackButton = false;
          this.showFile = true;
        }).catch(() => {
          // 用户点击“取消”后的处理逻辑
          console.log('用户取消了切换目录');
          return;
        });
      } else {
        // 如果 wenben3 为空，直接执行后续逻辑
        this.click3 = index; // 更新当前点击行的索引
        console.log("hang_id3", row.id);
        console.log("wenben3:", row.system_category_name);
        this.wenben3 = row.system_category_name;
        this.selectedRow3 = row;
        this.showBackButton = false;
        this.showFile = true;
      }
    },

    updateFilteredDatainfo() {
      console.log("2",this.filteredDatainfo);
      console.log("3",this.folderName);
      console.log("4",this.ltinfo);
      // 根据点击的目标值（例如 folderName）过滤原始数据，更新过滤后的数据数组
      this.filteredDatainfo = this.ltinfo.filter(item => item.template_description === this.folderName);
      //this.ltinfo = this.filteredDatainfo;
      // 重新计算序号
      let index = 1;
      this.filteredDatainfo.forEach(item => {
        item.template_level = index;
        index++;
      });
    },
    deleteRow(row) {
      //const id = row.id; // 获取当前行的id
      var data = [row.id];
      mzysystem_category2s.del(data).then(() => {
        this.$notify({
          title: "成功",
          message: "删除成功",
          type: "success",
          duration: 2000,
        });
      });
      this.getinfo().then(() => {
        this.updateFilteredDatainfo();
      });
    },
    // 通用
    add(type) {
      var temp = {};
      // var that = this;
      switch (type) {
        case 0:
          if (this.inputtext1 == "" || this.inputtext1 == null) {
            this.$message({
              message: "名称不能为空",
              type: "error",
            });
            return;
          }
          temp = {
            system_category_name: this.inputtext1,
          };

          mzysystem_categorys.add(temp).then(() => {
            this.dialogFormVisible = false;
            this.getList();
            this.inputtext1 = "";
            this.$notify({
              title: "成功",
              message: "创建成功",
              type: "success",
              duration: 2000,
            });
          });
          break;
        case 1:
          if (this.inputtext2 == "" || this.inputtext2 == null) {
            this.$message({
              message: "名称不能为空",
              type: "error",
            });
            return;
          }
          temp = {
            system_category_name: this.inputtext2,
            parent_id: this.selectedRow.id,
          };

          mzysystem_categorys.add(temp).then(() => {
            this.dialogFormVisible = false;

            console.log(999999);
            this.getSecondarydata(this.selectedRow.id);

            this.inputtext2 = "";
            this.$notify({
              title: "成功",
              message: "创建成功",
              type: "success",
              duration: 2000,
            });
          });
          break;
        case 2:
          if (this.inputtext3 == "" || this.inputtext3 == null) {
            this.$message({
              message: "名称不能为空",
              type: "error",
            });
            return;
          }
          temp = {
            template_name: this.inputtext3,
            parent_id: this.selectedRow2.id,
            template_level: 1,
          };
          console.log("id",this.selectedRow2.id);
          mzysystem_category2s.add(temp).then(() => {
            console.log(999999);
            this.getSecondarydata2(this.selectedRow2.id);

            this.inputtext4 = "";
            this.$notify({
              title: "成功",
              message: "创建成功",
              type: "success",
              duration: 2000,
            });
          });
          break;
        case 3:
          if (this.inputtext4 == "" || this.inputtext4 == null) {
            this.$message({
              message: "名称不能为空",
              type: "error",
            });
            return;
          }
          temp = {
            template_name: this.inputtext4,
            parent_id: this.selectedRow2.id,
            template_level: 2,
          };
          mzysystem_category2s.add(temp).then(() => {
            console.log(999999);
            this.getSecondarydata2(this.selectedRow2.id);

            this.inputtext4 = "";
            this.$notify({
              title: "成功",
              message: "创建成功",
              type: "success",
              duration: 2000,
            });
          });
          break;
        case 4:
          if (this.inputtext5 == "" || this.inputtext5 == null) {
            this.$message({
              message: "名称不能为空",
              type: "error",
            });
            return;
          }
          temp = {
            template_name: this.inputtext5,
            parent_id: this.selectedRow2.id,
            template_level: 3,
          };
          mzysystem_category2s.add(temp).then(() => {
            console.log(999999);
            this.getSecondarydata2(this.selectedRow2.id);

            this.inputtext4 = "";
            this.$notify({
              title: "成功",
              message: "创建成功",
              type: "success",
              duration: 2000,
            });
          });
          break;
        case 5:
          if (this.inputtext6 == "" || this.inputtext6 == null) {
            this.$message({
              message: "名称不能为空",
              type: "error",
            });
            return;
          }
          temp = {
            template_name: this.inputtext6,
            parent_id: this.selectedRow2.id,
            template_level: 4,
          };
          mzysystem_category2s.add(temp).then(() => {
            console.log(999999);
            this.getSecondarydata2(this.selectedRow2.id);

            this.inputtext4 = "";
            this.$notify({
              title: "成功",
              message: "创建成功",
              type: "success",
              duration: 2000,
            });
          });
          break;
      }
    },
    update(type) {
      console.log(type);
      // var temp = {};
      switch (type) {
        case 0:
          if (this.inputtext1 == "" || this.inputtext1 == null) {
            this.$message({
              message: "名称不能为空",
              type: "error",
            });
            return;
          }
          this.selectedRow.system_category_name = this.inputtext1;
          // temp = {
          //   system_category_name: this.inputtext1,
          // };
          mzysystem_categorys.update(this.selectedRow).then(() => {
            this.dialogFormVisible = false;
            this.getList();
            this.inputtext1 = "";
            this.$notify({
              title: "成功",
              message: "更新",
              type: "success",
              duration: 2000,
            });
          });
          break;
        case 1:
          if (this.inputtext2 == "" || this.inputtext2 == null) {
            this.$message({
              message: "名称不能为空",
              type: "error",
            });
            return;
          }
          this.selectedRow2.system_category_name = this.inputtext2;
          mzysystem_categorys.update(this.selectedRow2).then(() => {
            this.dialogFormVisible = false;
            this.getList();
            this.inputtext1 = "";
            this.$notify({
              title: "成功",
              message: "更新",
              type: "success",
              duration: 2000,
            });
          });
          break;
        case 2:
          if (this.inputtext3 == "" || this.inputtext3 == null) {
            this.$message({
              message: "名称不能为空",
              type: "error",
            });
            return;
          }
          this.selectedRow3.template_name = this.inputtext3;
          mzysystem_category2s.update(this.selectedRow3).then(() => {
            this.getSecondarydata2(this.selectedRow2.id);
            this.inputtext4 = "";
            this.$notify({
              title: "成功",
              message: "更新成功",
              type: "success",
              duration: 2000,
            });
          });
          break;
        case 3:
          if (this.inputtext4 == "" || this.inputtext4 == null) {
            this.$message({
              message: "名称不能为空",
              type: "error",
            });
            return;
          }
          this.selectedRow3.template_name = this.inputtext4;
          mzysystem_category2s.update(this.selectedRow3).then(() => {
            this.getSecondarydata2(this.selectedRow2.id);
            this.inputtext4 = "";
            this.$notify({
              title: "成功",
              message: "更新成功",
              type: "success",
              duration: 2000,
            });
          });
          break;
        case 4:
          if (this.inputtext5 == "" || this.inputtext5 == null) {
            this.$message({
              message: "名称不能为空",
              type: "error",
            });
            return;
          }
          this.selectedRow3.template_name = this.inputtext5;
          mzysystem_category2s.update(this.selectedRow3).then(() => {
            this.getSecondarydata2(this.selectedRow2.id);
            this.inputtext4 = "";
            this.$notify({
              title: "成功",
              message: "更新成功",
              type: "success",
              duration: 2000,
            });
          });
          break;
        case 5:
          if (this.inputtext6 == "" || this.inputtext6 == null) {
            this.$message({
              message: "名称不能为空",
              type: "error",
            });
            return;
          }

          this.selectedRow3.template_name = this.inputtext6;
          mzysystem_category2s.update(this.selectedRow3).then(() => {
            this.getSecondarydata2(this.selectedRow2.id);
            this.inputtext4 = "";
            this.$notify({
              title: "成功",
              message: "更新成功",
              type: "success",
              duration: 2000,
            });
          });
          break;
      }
    },
    handleDelete(row) {
      this.empty();
      this.getList();
      console.log("handleDelete");
      var data = [row.id];
      mzysystem_categorys.del(data).then(() => {
        this.$notify({
          title: "成功",
          message: "删除成功",
          type: "success",
          duration: 2000,
        });
      });
    },
    handleDelete2(row) {
      this.empty();
      this.getList();
      console.log("handleDelete");
      var data = [row.id];
      mzysystem_category2s.del(data).then(() => {
        this.$notify({
          title: "成功",
          message: "删除成功",
          type: "success",
          duration: 2000,
        });
      });
    },
    empty() {
      this.selectedRow = {};
      this.selectedRow2 = {};
      this.list3 = [];
      this.ServiceDirectoryList1 = [];
      this.ServiceDirectoryList2 = [];
      this.ServiceDirectoryList3 = [];
      this.ServiceDirectoryList4 = [];
    },

    // 第一层
    getList() {
      //数据获取
      // this.listLoading = true;
      mzysystem_categorys.getList(this.listQuery).then((response) => {
        this.list = response.data;
        // this.list = response.data.sort((a, b) => {
        //   return new Date(b.add_time) - new Date(a.add_time); // 按照日期从大到小排序
        // });
        response.columnFields.forEach((item) => {
          // 首字母小写
          item.columnName =
            item.columnName.substring(0, 1).toLowerCase() +
            item.columnName.substring(1);
        });
      });
    },
    getinfo() {
      //数据获取
      // this.listLoading = true;
      // mzysystem_category2s.getinfo(this.folderName).then((response) => {
      //   this.ltinfo = response.data;
      //   console.log("ltinfo",this.ltinfo.length);
      //   // 在组件挂载后，将后端数据赋值给 filteredDatainfo
      //   this.filteredDatainfo = this.ltinfo;
      // });
      return mzysystem_category2s.getinfo(this.folderName,this.wenben3)
        .then((response) => {
          this.ltinfo = response.data;
          console.log("ltinfo", this.ltinfo.length);
          // 在组件挂载后，将后端数据赋值给 filteredDatainfo
          this.filteredDatainfo = this.ltinfo;
        })
        .catch((error) => {
          console.error("获取数据时出错:", error);
        });
    },
    rowClickFirstTable(row, index) {
      // 先将所有父级元素的 isCollapsed 设置为 false
      this.list.forEach((parent) => {
          if (parent !== row) {
              parent.isCollapsed = false;
              // 同时将所有子元素的 isCollapsed 设置为 false
              if (parent.children) {
                  parent.children.forEach((child) => {
                      child.isCollapsed = false;
                  });
              }
          }
      });

      // 切换当前点击的父级元素的 isCollapsed 属性
      row.isCollapsed = !row.isCollapsed;

      // 更新当前点击行的索引
      this.clickedRowIndex = index;

      // 如果展开（isCollapsed 为 true），则获取子项数据
      if (row.isCollapsed && (!row.children || row.children.length === 0)) {
          this.getSecondarydata(row.id, row);
      }

      console.log("hang_id", row.id);
      //this.getSecondarydata2(row.id);
      this.selectedRow = row;
    },
    rowClickFirstTable4(row, index) {
      // 行点击
      this.getSecondarydata2(row.id);
      this.clickIndex = index; // 更新当前点击行的索引
      console.log("hang_id2", row.id);
      // 点击行
      this.selectedRow = row;
      this.clickedRowText2 = row.system_category_name;
      this.wenben = row.system_category_name;
      console.log("wenben2:",row.system_category_name);
      this.wenben2 = row.system_category_name;
      // 显示隐藏的 div 内容
      this.showList4Content = true;
    },
    setTableRowClassName({ row, rowIndex }) {
      // 设置行的类名
      console.log(rowIndex);
      return row === this.selectedRow ? "selected-row" : "";
    },
    //第二层
    setTableRowClassName2({ row, rowIndex }) {
      // 设置行的类名
      console.log(rowIndex);
      return row === this.selectedRow2 ? "selected-row" : "";
    },
    // getSecondarydata2(id) {
    //   var listQuery = { parent_id: id, page: 1, limit: 999, key: undefined };
    //   var list4 = this;
    //   mzysystem_category2s.LoadList(listQuery).then((response) => {
    //     console.log(22222);
    //     that.ServiceDirectoryList1 = response.data1.data;
    //     that.ServiceDirectoryList2 = response.data2.data;
    //     that.ServiceDirectoryList3 = response.data3.data;
    //     that.ServiceDirectoryList4 = response.data4.data;
    //   });
    // },
    getSecondarydata2(id) {
      var listQuery = { parent_id: id, page: 1, limit: 999, key: undefined };
      mzysystem_categorys.GetListByParent_id(listQuery).then((response) => {
        this.list4 = response.data;
        response.columnFields.forEach((item) => {
          // 首字母小写
          item.columnName =
            item.columnName.substring(0, 1).toLowerCase() +
            item.columnName.substring(1);
        });
      });
    },
    rowClickFirstTable2(row) {
      //行点击
      this.getSecondarydata2(row.id);
      // 点击行
      this['isCollapsed' + 3] = !this['isCollapsed' + 3];

      this.selectedRow2 = row;
      this.loadChildrenFromBackend(row);
    },
    //四层文件管理
    getSecondarydata(id, parent) {
      var listQuery = { parent_id: id, page: 1, limit: 999, key: undefined };
      mzysystem_categorys.GetListByParent_id(listQuery).then((response) => {
          // 确保父元素有 children 属性并赋值
          this.$set(parent, 'children', response.data);

          response.columnFields.forEach((item) => {
              item.columnName =
                  item.columnName.substring(0, 1).toLowerCase() +
                  item.columnName.substring(1);
          });
      });
    },
    rowFontWeight3(index) {
      return index === this.click3 ? 'bold' : 'normal';
    },
    rowClickFirstTable3(row) {
      //行点击
      this.getSecondarydata2(row.id);
      // 点击行

      this.selectedRow3 = row;
    },
    setTableRowClassName3({ row, rowIndex }) {
      // 设置行的类名
      console.log(rowIndex);

      let classes = "";
      if (row === this.selectedRow3) {
        classes += "selected-row";
      }
      return classes;
    },
  },
};
</script>
<style lang="scss">
@import "../../styles/cate.scss";
.dialog-mini .el-select {
  width: 100%;
  background-color: red;
}
.selected {
  background: #f3f3f3;
  color: #14bf96;
}
.selected-row {
  background: #f3f3f3;
  color: #14bf96;
}
.selected-row {
  background: #f3f3f3;
  color: #14bf96;
}
.havetemplate {
  background: #1dcb13;
}
.ishaveurl {
  // background: rgba(57, 239, 12, 0.8);
  background: rgb(244, 151, 11) !important;
}
.tables {
  max-height: 300px;
  overflow-y: auto !important;
}

.vuetable{
  position: absolute;
  top: 35%;
  left: 48%; /* 调整左侧边距 */
  width: 50%;
  background-color: white;
}

.container {
  position: relative;
}

.tree-container1 {
  position: absolute;
  top: 30;
  left: 10;
  width: 30%;
}

.vertical-line1 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 23%; /* 调整竖线的位置 */
  width: 1px;
  background-color: #000;
  content: "";
}

.vertical-line2 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 45%; /* 调整竖线的位置 */
  width: 1px;
  background-color: #d3d3d3; /* 设置竖线颜色为浅灰色 */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* 添加阴影效果 */
  content: "";
}

// .vertical-line3 {
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: 63%; /* 调整竖线的位置 */
//   width: 1px;
//   background-color: #000;
//   content: "";
// }

.tree-container2 {
  position: absolute;
  top: 30;
  left: 25%; /* 调整第二个部分的位置 */
  width: 69%; /* 调整第二个部分的宽度 */
}

.tree-container3 {
  position: absolute;
  top: 30;
  left: 45%; /* 调整第二个部分的位置 */
  width: 109%; /* 调整第二个部分的宽度 */
}

.document{
  position: absolute;
  top: 12%;
  left: 48%; /* 调整左侧边距 */
  width: 70%;
  background-color: white;
}

.folder-container{
  position: absolute;
  top: 12%;
  left: 50%; /* 调整左侧边距 */
  width: 50%;
}

.fname{
  position: absolute;
  top: 2%;
  left: 50%; /* 调整左侧边距 */
  width: 50%;
}

.fanhui{
  position: absolute;
  top: -65%;
  left: 50%; /* 调整左侧边距 */
}

.node-header {
  cursor: pointer;
}

// .node-name {
//   font-weight: bold;
// }

.el-icon-arrow-right.collapsed {
  transform: rotate(90deg);
}

.node-children {
  margin-left: 20px;
}

.node-actions {
  display: flex;
  align-items: center;
}

.el-input__inner {
  padding: 2px 5px; /* 调整文本框内边距 */
}

.el-button {
  margin-left: 5px; /* 调整按钮间距 */
}

.tree-node {
  margin-left: 20px;
}

.custom-table {
  border-collapse: collapse; /* 合并边框 */
  width: 80%;
}

.custom-table td {
  padding: 10px; /* 设置单元格内边距 */
  border: 1px solid black; /* 添加单元格边框 */
  text-align: center;
}

.child-node {
  margin-top: 20px !important;
  transition: height 0.3s ease;
}

.folder-container {
  display: flex;
  flex-wrap: wrap;
}

.folder-container {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 5%;
  left: 30%; /* 调整左侧边距 */
  width: 60%;
}

.folder-icon {
  width: 80px; /* 将文件夹宽度调整为80px */
  height: 100px; /* 将文件夹高度调整为100px */
  margin: 20px; /* 增加文件夹之间的间距 */
  position: relative;
}

.folder-label {
  position: absolute;
  bottom: -25px; /* 将文件夹标签向下偏移，使其居中显示 */
  left: 0;
  right: 0;
  text-align: center;
  font-size: 16px; /* 将文件夹标签字体大小调整为14px */
}


// .folder-label {
//   position: absolute;
//   bottom: -20px;
//   left: 0;
//   right: 0;
//   text-align: center;
//   font-size: 12px;
// }

.folder-container{
  position: absolute;
  top: 5%;
  left: 49%; /* 调整左侧边距 */
  width: 50%;
}

.item-value {
  display: inline-block; /* 使元素成为块级元素 */
  width: 450px; /* 设置宽度 */
}

.aac {
    font-size: 16px; /* 设置图标大小 */
    margin-right: 5px; /* 设置图标与文本之间的间距 */
    color: #999; /* 设置图标颜色 */
}

.bbc {
    font-size: 86px; /* 设置图标大小 */
    margin-right: 5px; /* 设置图标与文本之间的间距 */
    color: #999; /* 设置图标颜色 */
}

.cccc{
  background-color: white;
}

.axx{
  padding-left: 30px;
}
</style>
